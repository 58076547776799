.projects__wrappers {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
}

.project-card {
  width: 100%;
  border-radius: 1rem;
  background-color: $black-100;
  padding: 1.25rem;
  @include desktop-screen {
    width: 300px;
  }
}

.project-card__head {
  position: relative;
  height: 230px;
  width: 100%;
  > img {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
  }
}

.project-card__head-badge {
  position: absolute;
  margin: 0.75rem;
  inset: 0px;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background: linear-gradient( to right, #434343, #000000 );
  img {
    object-fit: contain;
    width: 50%;
    height: 50%;
  }
}

.project-card__content {
  margin-top: 1.25rem;
  h3 {
    font-size: 24px;
    font-weight: 700;
    color: $white;
  }
  p {
    margin-top: 0.5rem;
    font-size: 14px;
    color: $white-200;
  }
}

.project-card__foot {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.blue-text-gradient {
  background: linear-gradient( to top, #2f80ed, #56ccf2 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text-gradient {
  background: linear-gradient( to top, #11998e, #38ef7d );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: linear-gradient( to top, #ec008c, #fc6767 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purple-text-gradient {
  background: linear-gradient( to top, #9b51e0, #bb6bd9 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.yellow-text-gradient {
  background: linear-gradient( to top, #f2994a, #f2c94c );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.red-text-gradient {
  background: linear-gradient( to top, #eb5757, #ff6f61 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.orange-text-gradient {
  background: linear-gradient( to top, #f5af19, #f12711 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}